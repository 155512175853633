const simple = Object.assign(() => null, { isRequired: () => null })
const funky = () => simple
export const array = simple
export const bigint = simple
export const bool = simple
export const func = simple
export const number = simple
export const object = simple
export const string = simple
export const symbol = simple
export const any = simple
export const element = simple
export const elementType = simple
export const node = simple

export const arrayOf = funky
export const instanceOf = funky
export const objectOf = funky
export const oneOf = funky
export const oneOfType = funky
export const shape = funky
export const exact = funky

const defaultExport = {
  any,
  array,
  arrayOf,
  bigint,
  bool,
  element,
  elementType,
  exact,
  func,
  instanceOf,
  node,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
  symbol,
}

export const PropTypes = defaultExport

defaultExport.PropTypes = PropTypes

export default defaultExport
